import * as React from 'react'
import styled from 'styled-components/macro'

import {QUERIES} from 'common/constants'

const Container = styled.div`
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0.75) 100%
  );
`
const BannerImg = styled.img`
  height: auto;
  width: 100%;
  filter: brightness(60%);
  min-height: 300px;
  object-fit: cover;
  @media ${QUERIES.tabletAndUp} {
    object-fit: initial;
  }
`

const Icon = styled.img`
  background-color: transparent;
  max-height: 75px;
  @media ${QUERIES.tabletAndUp} {
    max-height: initial;
  }
`

const IconSection = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 16px;
  top: 5%;
  @media ${QUERIES.tabletAndUp} {
    right: 128px;
  }
`

const Heading = styled.div`
  position: absolute;
  left: 16px;
  bottom: 5%;
  @media ${QUERIES.tabletAndUp} {
    left: 128px;
    bottom: 15%;
  }
  width: 100%;
  right: 128px;
`
const Title = styled.div`
  font-family: Rubik;
  color: white;
  font-style: italic;
  font-weight: 700;
  font-size: calc(16 / 16 * 1rem);
  @media ${QUERIES.tabletAndUp} {
    font-size: calc(32 / 16 * 1rem);
  }
  text-transform: uppercase;
`
const Message = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: calc(16 / 16 * 1rem);
  line-height: 30px;
  text-transform: uppercase;
  font-style: italic;
  max-width: 60%;
  @media ${QUERIES.tabletAndUp} {
    font-size: calc(56 / 16 * 1rem);
    line-height: 66px;
  }
  color: #ffffff;
`

const TopImageSection = ({selectedPartner}) => {
  return (
    <Container>
      <BannerImg src={selectedPartner.image} alt="Event Planning" />
      <IconSection>
        <Icon src={selectedPartner.icon} />
        {selectedPartner.icon2 && <Icon src={selectedPartner.icon2} />}
      </IconSection>
      <Heading>
        <Title>
          {selectedPartner.title
            ? selectedPartner.title
            : 'Premier Destination Partner'}
        </Title>
        <Message>{selectedPartner.name}</Message>
      </Heading>
    </Container>
  )
}
export default TopImageSection
