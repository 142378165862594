import Button from 'common/Button'
import * as React from 'react'
import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

const Background = styled.img`
  height: 150px;
  object-fit: cover;
  @media ${QUERIES.tabletAndUp} {
    height: auto;
    object-fit: initial;
  }
`

const MessageButton = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  a {
    text-decoration: none;
  }
  button {
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }
`
const Message = styled.div`
  font-family: 'Rubik';
  font-style: italic;
  font-size: calc(32 / 16 * 1rem);
  text-transform: uppercase;
`

const LodgingGolfCard = ({image, type, link}) => {
  return (
    <Container>
      <Background src={image} />
      <MessageButton>
        <Message>{type}</Message>
        <a href={link} target="_blank" rel="noreferrer">
          <Button>Learn More</Button>
        </a>
      </MessageButton>
    </Container>
  )
}
export default LodgingGolfCard
