import * as React from 'react'

import styled from 'styled-components/macro'

import TopImageSection from './TopImageSection'
import LodgingGolfCard from './LodgingGolfCard'
import {QUERIES} from 'common/constants'
import TeamImage from 'assets/images/SunAndFun/SunAndFunTravelsGolfTeamShot.jpg'
import Icon from 'assets/images/SunAndFun/Peter-Drab-Logo.png'
import QR from 'assets/images/SunAndFun/SunAndFunQuote-QR.png'
import TeamMember from 'assets/images/SunAndFun/Golf-TeamMember.jpg'
import ContactUs from 'assets/images/SunAndFun/ContactUs.jpg'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(52, 52, 52, 0.85) 8.87%,
    rgba(26, 26, 26, 0.85) 100%
  );
`

const BottomSection = styled.div`
  padding: 48px 16px;
  @media ${QUERIES.tabletAndUp} {
    padding: 88px 0;
  }
  max-width: 1184px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const AboutSection = styled.div`
  text-align: center;
  font-family: 'Mulish';
  h5 {
    font-size: calc(18 / 16 * 1rem);
    @media ${QUERIES.tabletAndUp} {
      font-size: calc(28 / 16 * 1rem);
    }
  }
  p,
  ul {
    font-weight: 300;
    font-size: calc(14 / 16 * 1rem);
    @media ${QUERIES.tabletAndUp} {
      font-size: calc(24 / 16 * 1rem);
    }
    padding-bottom: 16px;
  }
  ul {
    list-style-position: inside;
  }
`
const DedicatedSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 0;
  @media ${QUERIES.tabletAndUp} {
    padding: 96px 0;
    gap: 64px;
    flex-direction: row;
  }
`

const DedicatedQR = styled.div`
  margin: 0 auto;
`
const DedicatedImage = styled.div`
  img {
    width: 400px;
    height: 384px;
    object-fit: cover;
  }
`
const DedicatedText = styled.div`
  max-width: 404px;
  line-height: 26px;
  h1 {
    font-size: calc(40 / 16 * 1rem);
    line-height: 47px;
    text-align: initial;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 24px;
  }
  h3 {
    font-size: calc(24 / 16 * 1rem);
    line-height: 30px;
  }
  h4 {
    font-family: 'Mulish';
    font-weight: 400;
    font-style: italic;
    font-size: calc(18 / 16 * 1rem);
  }
  p {
    font-family: 'Mulish';
    font-weight: 300;
    font-size: calc(16 / 16 * 1rem);
    a {
      color: white;
    }
  }
`

const SunAndFun = () => {
  const selectedPartner = {
    image: TeamImage,
    icon: Icon,
    name: 'Sun & Fun Travels',
    title: 'Premier Travel Partner',
  }
  return (
    <Container>
      <TopImageSection selectedPartner={selectedPartner} />
      <BottomSection>
        <AboutSection>
          <h5>About</h5>
          <p>
            {`Are you planning a small weekend escape with your significant other? Or perhaps a large golf trip for your local country club? At Sun & Fun Travels, our dedicated team is here to provide you with exceptional service from start to finish. From the moment we begin our initial consultation to the follow-up after your trip, we're committed to making your travel experience seamless and unforgettable. Our friendly, well-trained travel advisors are experts in a wide range of travel experiences and destinations worldwide. Let us handle the details, so you can focus on making memories.`}
            <br />
            <br />
            Our friendly, well trained travel advisors specialize in different
            forms of travel all over the world, including:
          </p>
          <ul>
            <li>
              All-inclusive vacations (couple, family and multigenerational
              vacations)
            </li>
            <li>Destination weddings/Honeymoons</li>
            <li>River and ocean cruises</li>
            <li>Theme park vacations</li>
            <li>
              Europe and other international destinations (Tours, custom
              itineraries, etc.)
            </li>
            <li>Private yacht charter</li>
            <li>Golf trips (large and small group)</li>
            <li>Dive trips (Including liveaboard options)</li>
          </ul>
        </AboutSection>
        <DedicatedSection>
          <DedicatedQR>
            <img src={QR} />
          </DedicatedQR>
          <DedicatedImage>
            <img src={TeamMember} />
          </DedicatedImage>
          <DedicatedText>
            <h1>Our dedicated travel partners</h1>
            <h3>Scan the QR Code for a Quote</h3>
            <h4>Pete Drab, Owner</h4>
            <p>Sun & Fun Travels, LLC</p>
            <p>(815) 409-2705</p>
            <p>pete.drab@sunandfuntravels.com</p>
            <p>
              <a href="https://www.sunandfuntravels.com">
                www.sunandfuntravels.com
              </a>
            </p>
          </DedicatedText>
        </DedicatedSection>
        <LodgingGolfCard image={ContactUs} type={'Contact'} link="/contact" />
      </BottomSection>
    </Container>
  )
}
export default SunAndFun
